<template>
  <div>
    <Navbar />
    <PageTitle pageTitle="Wholesalers" subTitle="Become from a one stop supplier. a one stop solution provider." />
    <div class="container">
      <div class="col-12">
        <div class="overview-box mb-3 mb-md-5 my-5">
          <div class="overview-image">
              <div class="image">
                <img src="../../assets/images/machine-learning/owl-value.jpg" alt="Owl-Automata Value offering" class="rounded-10" />
              </div>
          </div>
          <div class="overview-content mb-0">
              <div class="content right-content">
                  <h2>Value offering</h2>
                  <div class="features-text">
                      <h4><i class="flaticon-tick"></i> Shifting the business paradigm from selling components, to selling solutions, applications and functions, allowing to be competitive while maintaining price levels and higher margins</h4>
                  </div>

                  <div class="features-text">
                      <h4><i class="flaticon-tick"></i> Ability to alter specifications by understanding project needs and adjusting proposal accordingly</h4>
                  </div>

                  <div class="features-text">
                      <h4><i class="flaticon-tick"></i> Annual turnover increase by entering the rapidly growing hospitality market with a solution that has been proven to work</h4>
                  </div>

                  <div class="features-text">
                      <h4><i class="flaticon-tick"></i> Provision of a holistic solution to the customer by driving horizontal integration of all areas of hospitality applications like GRMS, Access control, HVAC, Occupancy detection, Door lock systems, PMS, LV/MV, and audio/video integration</h4>
                  </div>
                  <div class="features-text">
                      <h4><i class="flaticon-tick"></i> Expand your customer base by providing technical and engineering services</h4>
                  </div>
              </div>
          </div>
        </div>
        <WeAlwaysTryToUnderstandWholesalers />
      </div>
      <div class="mx-auto text-center mb-5">
            <p class="mt-4">*We remain at your disposal for any further information or clarification you might require.</p>
            <div class="contact-btn mt-5">
                <router-link to="/contact" class="default-btn">
                    Contact Us <span></span>
                </router-link>
            </div>
        </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import Footer from '../Layout/Footer'
import WeAlwaysTryToUnderstandWholesalers from '../Common/WeAlwaysTryToUnderstandWholesalers'

export default {
  components: { 
      Navbar,
      PageTitle,
      Footer,
      WeAlwaysTryToUnderstandWholesalers,
  }
}
</script>