<template>
    <div>
        <Navbar />
        <MainBannerFour />
        <OurServices />
        <LetsGetToWork />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import MainBannerFour from '../HomeFive/MainBannerFour'
import OurServices from '../HomeFive/OurServices'
import LetsGetToWork from '../Common/LetsGetToWork'
import Footer from '../Layout/Footer'

export default {
    components: {
        Navbar,
        MainBannerFour,
        OurServices,
        LetsGetToWork,
        Footer,
    }
}
</script>