<template>
    <div>
        <Navbar />
        <WeAredDynamicTeam />
        <EngagingNew class="pt-100" />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import WeAredDynamicTeam from '../Common/WeAredDynamicTeam'
import EngagingNew from '../AboutTwo/EngagingNew'
import Footer from '../Layout/Footer'

export default {
    components: { 
        Navbar,
        WeAredDynamicTeam,
        EngagingNew,
        Footer,
    }
}
</script>