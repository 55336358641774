<template>
  <div>
      <Navbar />
      <PageTitle pageTitle="Not Found" />
      <div class="text-center my-5 px-4">
        <img src="../../assets/images/404.png" class="m-auto" width="800" height="800" alt="">
      </div>
      <div class="text-center mb-4">
        <router-link to="/" class="default-btn mr-3">
            Back to Home
        </router-link>
      </div>
      <Footer />
  </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import Footer from '../Layout/Footer'

export default {
  components: {
      Navbar,
      PageTitle,
      Footer,
  },
}
</script>