<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="Our Services" subTitle="As an external center of competence exclusively specialized in the hospitality sector, we offer a range of engineering services to KNX manufacturers and other stakeholders. Our services include specification, calculation of solutions, drawing and schematics, functional description, sales argumentation, support for meetings and presentations, and upselling. We also provide additional services such as programming support and drafting of sales argumentation." />
        <ServicesCard />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import ServicesCard from '../ServicesThree/ServicesCard'
import Footer from '../Layout/Footer'
import PageTitle from '../Common/PageTitle'

export default {
    components: { 
        Navbar,
        ServicesCard,
        Footer,
        PageTitle,
    }
}
</script>