<template>
    <div class="choose-area-two pt-4">
        <div class="container">
            <div class="row align-items-center mb-lg-5">
                <div class="col-lg-6 order-2 order-lg-1">
                    <div class="choose-content">
                        <div class="section-title text-lg-start text-center">
                            <h2 class="mb-3">Value Offering</h2>
                            <ul style="list-style: inside;" class="ps-0"> 
                                <li class="mb-2">Focus on solutions and features that provide value for stakeholders, enabling competitiveness while maintaining price levels and higher margins</li> 
                                <li class="mb-2">Ability to adapt specifications based on project needs and adjust proposals accordingly</li>
                                <li class="mb-2">Increase annual turnover by entering the rapidly growing hospitality market with a proven solution</li>
                                <li class="mb-2">Access to accumulated field knowledge from 10 years of experience in hospitality projects</li> 
                                <li class="mb-2">Providing a comprehensive solution by driving horizontal integration of all areas of hospitality applications, including GRMS, access control, HVAC, occupancy detection, door lock systems, and PMS</li> 
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 order-1 order-lg-2">
                    <div class="choose-image mb-4 mb-lg-0 mt-0">
                        <img src="../../assets/images/machine-learning/owl-value.jpg" class="rounded-10" alt="Owl-Automata Value Offering">
                    </div>
                </div>
            </div>
            <div class="row align-items-center mb-lg-5">
                <div class="col-lg-6 col-md-12">
                    <div class="choose-image mt-2">
                        <img src="../../assets/images/machine-learning/owl-services.png" alt="Owl-Automata Services">
                    </div>
                </div>
                <div class="col-lg-6 mt-4 mt-lg-0">
                    <div class="choose-content">
                        <div class="section-title text-lg-start text-center">
                            <h2>Our Services</h2>

                            <p>As an external center of competence exclusively specialized in the hospitality sector, we offer a range of engineering services to KNX manufacturers and other stakeholders. Our services include specification, calculation of solutions, drawing and schematics, functional description, sales argumentation, support for meetings and presentations, and upselling. We also provide additional services such as programming support and drafting of sales argumentation.</p>

                            <router-link to="/services" class="default-btn mt-4">
                                Our Services <span></span>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'engagingnew'
    }
</script>