<template>
  <div class="features-area">
      <div class="container px-0">
          <div class="section-title">
              <h2>Services</h2>
              <p>Since the end product should be a working guest room, the scope of our services spans over all phases of the project, in order to address all white spots in the project cycle.</p>
          </div>

          <div class="features-list-tab">
              <div class="tabs">
                  <ul class="tabs__header">
                      <li 
                          class="tabs__header-item" 
                          v-for="tab in tabs" 
                          v-on:click="selectTab (tab.id)" 
                          v-bind:class="{ 'active': activeTab == tab.id }"
                          :key="tab.id"
                      >
                          <i :class="tab.icon"></i>
                          {{tab.title}}
                      </li>
                  </ul>
              </div>
              <div class="tabs__container">
                  <div 
                      class="tabs__list"
                      ref='tabsList'
                  >
                      <div 
                          class="tabs__list-tab"
                          v-for="tab in tabs"
                          v-bind:class="{ 'active': activeTab == tab.id }"
                          :key="tab.id"
                      >
                          <div class="features-overview">
                              <div class="overview-content">
                                  <div class="content">
                                      <h2>{{tab.title}}</h2>
                                      <p>{{tab.description}}</p>

                                      <ul class="features-list">
                                          <li
                                              v-for="features in tab.featuresList"
                                              :key="features.id"
                                          >
                                              <span>
                                                  <i :class="features.icon"></i> 
                                                  {{features.list}}
                                              </span>
                                          </li>
                                      </ul>
                                  </div>
                              </div>

                              <div class="overview-image">
                                  <div class="image">
                                      <img :src="tab.image" alt="Owl Automata Design Phase, Engineering Phase, Construction phase, Completion Phase" class="rounded-10">
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      <div class="shape-img7"><img src="../../assets/images/shape/shape-7.png" alt="image"></div>
      <div class="shape-img2"><img src="../../assets/images/shape/shape-2.svg" alt="image"></div>
      <div class="shape-img3"><img src="../../assets/images/shape/shape-3.svg" alt="image"></div>
      <div class="shape-img4"><img src="../../assets/images/shape/shape-4.png" alt="image"></div>
  </div>
</template>

<script>
export default {
  name: 'WeAlwaysTryToUnderstandDevelopers',
  data () {
      return {
          activeTab: 1,
          offsetRight: 0,
          tabs: [
              {
                  id: 1,
                  icon: 'flaticon-architecture',
                  title: 'Design phase',
                  subTitle: 'Define Your Choices',
                  description: '',
                  featuresList: [
                      {
                          id: 1,
                          icon: 'flaticon-tick',
                          list: 'Determination of project requirements',
                      },
                      {
                          id: 2,
                          icon: 'flaticon-tick',
                          list: 'Deriving localized implementation and design from project / customer specifications',
                      },
                      {
                          id: 3,
                          icon: 'flaticon-tick',
                          list: 'Drawings per typical room and functional description of the system',
                      },
                      {
                          id: 4,
                          icon: 'flaticon-tick',
                          list: 'Drafting of solution presentation',
                      },
                  ],
                  image: require('../../assets/images/features/features-2.png'),
              },
              {
                  id: 2,
                  icon: 'flaticon-digital-marketing',
                  title: 'Engineering phase',
                  description: '',
                  featuresList: [
                      {
                          id: 1,
                          icon: 'flaticon-tick',
                          list: 'Detailed functional and application design (Control Strategy Configuration)',
                      },
                      {
                          id: 2,
                          icon: 'flaticon-tick',
                          list: 'Added value engineering.',
                      },
                      {
                          id: 3,
                          icon: 'flaticon-tick',
                          list: 'Provision of engineering guidelines',
                      },
                      {
                          id: 4,
                          icon: 'flaticon-tick',
                          list: 'Vertical integration and interoperation of third-party systems like HVAC, PMS, Online Locks Server etc.',
                      },
                      {
                          id: 5,
                          icon: 'flaticon-tick',
                          list: 'Verification of Golden unit',
                      },
                  ],
                  image: require('../../assets/images/features/features-3.png'),
              },
              {
                  id: 3,
                  icon: 'flaticon-analytics',
                  title: 'Construction phase',
                  featuresList: [
                      {
                          id: 1,
                          icon: 'flaticon-tick',
                          list: 'Design alterations',
                      },
                      {
                          id: 2,
                          icon: 'flaticon-tick',
                          list: 'Checklist for commissioning workflow',
                      },
                      {
                          id: 3,
                          icon: 'flaticon-tick',
                          list: 'Provision of engineering, commissioning, and programming support (Hourly rate fees applied)',
                      },
                  ],
                  image: require('../../assets/images/features/features-4.png'),
              },
              {
                  id: 4,
                  icon: 'flaticon-data',
                  title: 'Completion phase',
                  featuresList: [
                      {
                          id: 1,
                          icon: 'flaticon-tick',
                          list: 'Checklist for handover and acceptance',
                      },
                      {
                          id: 2,
                          icon: 'flaticon-tick',
                          list: 'Overview of handover and acceptance process (to be charged separately, if requested on project basis)',
                      },
                  ],
                  image: require('../../assets/images/features/features-5.png'),
              },
          ]
      }
  },
  methods: {
      selectTab (id) {
          let tabsList = this.$refs.tabsList
          this.activeTab = id
          this.offsetRight = tabsList.clientWidth * (id - 1)
          tabsList.style.right = this.offsetRight + 'px'
      }
  },
  mounted () {
      let tabsList = this.$refs.tabsList
      tabsList.style.right = this.offsetRight + 'px'
  }
}
</script>