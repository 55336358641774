<template>
  <div>
    <Navbar />
    <PageTitle pageTitle="Fire Ant" />
    <div class="container pb-100">
      <div class="row mt-5">
        <div class="col-12 text-center d-flex align-self-center justify-content-center">
          <img src="../../assets/images/fire-ant-1.png" alt="" />
        </div>
        <div class="col-12 text-center">
          <h3 class="mt-0">Fi<span style="color: #ef3f23;">r</span>e <span style="color: #ef3f23;">A</span>nt</h3>
          <h5><span style="color: #ef3f23;">r</span>epetition <span style="color: #ef3f23;">A</span>utomated</h5>
          <p>When programming an automation project, you often come across repetitive tasks like creating multiple rooms for a hotel visualization. Up to now these tasks are being done manually.<br>
          This is a costly procedure that either requires to retain a permanent staff or outsource the task with loss of control over the quality of the outcome.<br>
          Furthermore, these extensive copying and pasting operations are prone to mistakes that are usually discovered by the end user after the project deployment</p>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-12 text-center">
          <p>Fi<span style="color: #ef3f23;">r</span>e <span style="color: #ef3f23;">A</span>nt Haa<span style="color: #ef3f23;">S</span> is revolutionizing automation projects and is bringing an end to practices by employing Robotic Process Automation for completing repetitive tasks automatically, without human input. We are driving your costs and your labor down, while introducing high standards of quality in the end product with zero errors, enabling you to invest your resources solely on application design.</p>
        </div>
        <div class="col-12 text-center mt-4">
          <h5 class="mb-4">We automate your tasks, so that you can focus on your customer’s automation needs.</h5>
          <h6 class="mb-4 h5 fw-bold">Let us “<span style="color: #ef3f23;">copy</span>” and “<span style="color: #ef3f23;">paste</span>”, so that you can <span style="color: #ef3f23;">create</span> and <span style="color: #ef3f23;">pioneer</span></h6>
          <router-link to="/contact" class="default-btn">
            Get a quotation today!
          </router-link>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "../Layout/Navbar";
import PageTitle from "../Common/PageTitle";
import Footer from "../Layout/Footer";

export default {
  components: {
    Navbar,
    PageTitle,
    Footer,
  },
};
</script>
