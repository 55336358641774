<template>
    <div>
        <Navbar />
        <ContactContent />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import ContactContent from '../Contact/ContactContent'
import Footer from '../Layout/Footer'

export default {
    components: {
        Navbar,
        ContactContent,
        Footer,
    }
}
</script>