<template>
    <div class="preloader">
        <div class="text-center mt-5">
            <div class="bg-black d-inline-block text-center mx-5 px-4 py-4 mt-5" style="border-radius: 30px;">
                <img src="../../assets/images/logo-white.svg" alt="logo" width="150">
            </div>
        </div>
        <div class="loader">
            <div class="shadow"></div>
            <div class="box"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Preloader'
    }
</script>