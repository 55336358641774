<template>
  <div>
      <Navbar />
      <PageTitle pageTitle="Hotel as a Service" />
      <HaasContent />
      <Footer />
  </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import HaasContent from '../Haas/HaasContent'
import Footer from '../Layout/Footer'

export default {
  components: {
      Navbar,
      PageTitle,
      HaasContent,
      Footer,
  }
}
</script>