import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store";
import axios from 'axios'
import VueAxios from 'vue-axios'
import Toaster from '@meforma/vue-toaster';
import VueGtag from "vue-gtag";
import './assets/custom.scss';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCbdAvovZs2voNCqYdva_u8Ko9ClFq_IgY",
  authDomain: "owl-automata.firebaseapp.com",
  projectId: "owl-automata",
  storageBucket: "owl-automata.appspot.com",
  messagingSenderId: "126282828244",
  appId: "1:126282828244:web:a71b5d61f98bb59866ae41"
};

initializeApp(firebaseConfig);

const app = createApp(App)
.use(router)
.use(store)
.use(Toaster)
app.use(VueAxios, axios)
.use(VueGtag, {
  deferScriptLoad: true,
  config: { id: "G-EQZ1WZJN1X" }
}).mount('#app')