<template>
  <div>
    <Navbar />
    <PageTitle pageTitle="Chameleon" />
    <div class="container pb-100">
      <div class="row mt-5">
        <div class="col-12 col-md-4 text-center d-flex align-self-center justify-content-center">
          <img src="../../assets/images/chameleon-1.png" alt="" />
        </div>
        <div class="col-12 col-md-8">
          <h3 class="mt-0">Chame<span style="color: #ef3f23;">l</span>e<span style="color: #ef3f23;">o</span>n</h3>
          <span class="h6 font-weight-bold d-block"><strong>*This product is currently only available for the Greek and Cyprus market</strong></span>
          <p>Το Chame<span style="color: #ef3f23;">l</span>e<span style="color: #ef3f23;">o</span>n είναι το πρώτο προϊόν της σειράς Haa<span style="color: #ef3f23;">S</span> και απευθύνεται σε αυτοματιστές που θέλουν να δραστηριοποιηθούν στον χώρο του ξενοδοχειακού αυτοματισμού.</p>
          <p>Είναι καταστάλαγμα της εμπειρίας περισσοτέρων από 30.000 δωματίων ξενοδοχείου και βασίζεται σε αποκεντρωμένη αρχιτεκτονική και ανοιχτό πρωτόκολλο επικοινωνίας, διασφαλίζοντας με αυτό τον τρόπο την αξιοπιστία του και την μέγιστη συνδεσιμότητά του με τρίτα συστήματα. Το σύστημα φέρνει για πρώτη φορά τον πλέον διαδεδομένο ελεγκτή της αγοράς, το LOGO! της Siemens στο οικοσύστημα του κορυφαίου πρωτόκολλου αυτοματισμού, το ΚΝΧ, σαν μια οικονομική αλλά πλούσια σε λειτουργίες λύση στον αυτοματισμό δωματίου.</p>
          <p>Το Chame<span style="color: #ef3f23;">l</span>e<span style="color: #ef3f23;">o</span>n HaaS αποτελεί μία plug and play πλήρη λύση προηγμένου ξενοδοχειακού αυτοματισμού για ξενοδοχειακές μονάδες χωρητικότητας από ένα έως ενενήντα δωμάτια.</p>
        </div>
      </div>
      <div class="row mt-5">
        <img src="../../assets/images/chameleon-gif.gif" alt="" class="mx-auto">
        <div class="col-12 col-lg-8 mx-auto text-center">
          <h3 class="text-center">Χαρακτηριστικά</h3>
          <img src="../../assets/images/chameleon-2.png" alt="" class="mx-auto">
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-12 col-lg-9 mx-auto">
          <h3 class="text-center">Προγραμματίστε το ξενοδοχείο σας με 5 απλά βήματα </h3>
          <img src="../../assets/images/intro_flowchart.png" alt="" />
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-12 text-center">
          <h3 class="text-center">Videos</h3>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-12 col-sm-6 text-center mb-4 mb-sm-0">
          <h4 class="text-center">Ελληνικά</h4>
          <iframe width="100%" height="315" src="https://www.youtube.com/embed/J5LjUkIY0A8?si=GejWe4Ir9Fc8R3C5" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
        <div class="col-12 col-sm-6 text-center">
          <h4 class="text-center">English</h4>
          <iframe width="100%" height="315" src="https://www.youtube.com/embed/o9LP3BNHEY8?si=hDO6Ioyq4NYEr5um" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "../Layout/Navbar";
import PageTitle from "../Common/PageTitle";
import Footer from "../Layout/Footer";

export default {
  components: {
    Navbar,
    PageTitle,
    Footer,
  },
};
</script>
