<template>
  <div>
      <Navbar />
      <PageTitle pageTitle="Developers" subTitle="Services targeted at developers offer a manufacturer agnostic approach aimed at delivering the optimal solution while providing added value through all the stages of a project." />
      <div class="container">
        <div class="overview-box pb-2 pb-md-5 mt-5">
          <div class="overview-image">
              <div class="image">
                  <img src="../../assets/images/machine-learning/owl-value.jpg" alt="image">
              </div>
          </div>
          <div class="overview-content mb-0">
              <div class="content right-content">
                  <h2>Value offering</h2>
                  <div class="features-text">
                      <h4><i class="flaticon-tick"></i> Shifting the business paradigm to solution-oriented design, focusing on applications and functionality</h4>
                  </div>

                  <div class="features-text">
                      <h4><i class="flaticon-tick"></i> Adaptation of project specifications and guidelines to meet local specificities.</h4>
                  </div>

                  <div class="features-text">
                      <h4><i class="flaticon-tick"></i> Developing and deploying unbiased and manufacturer agnostic solutions</h4>
                  </div>

                  <div class="features-text">
                      <h4><i class="flaticon-tick"></i> Provision of a holistic solution to the customer by driving horizontal integration of all areas of hospitality applications like GRMS, Access control, HVAC, Occupancy detection, Door lock systems, PMS, LV/MV, and audio/video integration</h4>
                  </div>
              </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <WeAlwaysTryToUnderstandDevelopers />
          </div>
          <div class="col-md-6"></div>
        </div>
        <div class="mx-auto text-center mb-5">
            <p class="mt-4">*We remain at your disposal for any further information or clarification you might require.</p>
            <div class="contact-btn mt-5">
                <router-link to="/contact" class="default-btn">
                    Contact Us <span></span>
                </router-link>
            </div>
          </div>
      </div>
      <Footer />
  </div>
</template>

<script>
import WeAlwaysTryToUnderstandDevelopers from '../Common/WeAlwaysTryToUnderstandDevelopers'
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import Footer from '../Layout/Footer'

export default {
  components: { 
      Navbar,
      PageTitle,
      Footer,
      WeAlwaysTryToUnderstandDevelopers,
  }
}
</script>