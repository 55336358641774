<template>
  <div>
      <Navbar />
      <PageTitle pageTitle="Cookie Policy" />
      <CookiePolicyContent />
      <Footer />
  </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import CookiePolicyContent from '../CookiePolicy/CookiePolicyContent'
import Footer from '../Layout/Footer'

export default {
  components: { 
      Navbar,
      PageTitle,
      CookiePolicyContent,
      Footer,
  }
}
</script>