<template>
  <div>
      <Navbar />
      <PageTitle pageTitle="Serving a diverse group of customers" subTitle="At OWL Automata, we cater to the needs of various stakeholders in the building automation industry." />
      <div class="ptb-100">
          <div class="container">
              <div class="row">
                  <div class="col-lg-4 col-sm-6">
                      <div class="service-card-one bg-white center">
                          <div class="icon">
                              <i class="bx bx-laptop"></i>
                              <i class='bx bxs-badge-check check-icon'></i>
                          </div>
                          <h3>
                              Developers
                          </h3>
                          <p>Acquire the means for deployment of an optimal manufacturer agnostic solution, easily adapting in different projects and local specificities.</p>
                          <router-link to="/developers" class="default-btn mt-4">
                              Read More <span></span>
                          </router-link>
                      </div>
                  </div>
                  <div class="col-lg-4 col-sm-6">
                      <div class="service-card-one bg-white center">
                          <div class="icon">
                              <i class="bx bxs-user-badge"></i>
                              <i class='bx bxs-badge-check check-icon'></i>
                          </div>
                          <h3>
                              Project managers
                          </h3>
                          <p>Make a specialized technical partner for every stage of the project; Design, Engineering, Installation and Completion phases.</p>
                          <router-link to="/project-managers" class="default-btn mt-4">
                              Read More <span></span>
                          </router-link>
                      </div>
                  </div>
  
                  <div class="col-lg-4 col-sm-6">
                      <div class="service-card-one bg-white center">
                          <div class="icon">
                              <i class="bx bx-cog"></i>
                              <i class='bx bxs-badge-check check-icon'></i>
                          </div>
                          <h3>
                              Manufacturers
                          </h3>
                          <p>Make a specialized technical partner for every stage of the project; Design, Engineering, Installation and Completion phases.</p>
                          <router-link to="/manufacturers" class="default-btn mt-4">
                              Read More <span></span>
                          </router-link>
                      </div>
                  </div>
                  <div class="col-lg-4 col-sm-6">
                      <div class="service-card-one bg-white center">
                          <div class="icon">
                              <i class="bx bx-dollar-circle"></i>
                              <i class='bx bxs-badge-check check-icon'></i>
                          </div>
                          <h3>
                              Wholesalers
                          </h3>
                          <p>Service your customers better. Become an one stop solution provider.</p>
                          <router-link to="/wholesalers" class="default-btn mt-4">
                              Read More <span></span>
                          </router-link>
                      </div>
                  </div>
                  <div class="col-lg-4 col-sm-6">
                      <div class="service-card-one bg-white center">
                          <div class="icon">
                              <i class="bx bx-network-chart"></i>
                              <i class='bx bxs-badge-check check-icon'></i>
                          </div>
                          <h3>
                              System Integrators
                          </h3>
                          <p>Access the knowledge of hospitality applications and address third party systems integration.</p>
                          <router-link to="/system-integrators" class="default-btn mt-4">
                              Read More <span></span>
                          </router-link>
                      </div>
                  </div>
                  <div class="col-lg-4 col-sm-6">
                      <div class="service-card-one bg-white center">
                          <div class="icon">
                              <i class="bx bxs-user-voice"></i>
                              <i class='bx bxs-badge-check check-icon'></i>
                          </div>
                          <h3>
                              Consultants
                          </h3>
                          <p>Translate customer specifications and deliver a detailed project definition based on functions.</p>
                          <router-link to="/consultants" class="default-btn mt-4">
                              Read More <span></span>
                          </router-link>
                      </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 mx-auto">
                      <div class="service-card-one bg-white center">
                          <div class="icon">
                              <i class="bx bxs-user-check"></i>
                              <i class='bx bxs-badge-check check-icon'></i>
                          </div>
                          <h3>
                              Contractors
                          </h3>
                          <p>Design/cost review and solution benchmarking. Achieve/monitor project objectives and normalize handover, acceptance processes.</p>
                          <router-link to="/contractors" class="default-btn mt-4">
                              Read More <span></span>
                          </router-link>
                      </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 mx-auto">
                      <div class="service-card-one bg-white center">
                          <div class="icon">
                              <i class="bx bxs-hotel"></i>
                              <i class='bx bxs-badge-check check-icon'></i>
                          </div>
                          <h3>
                              Hotel operators
                          </h3>
                          <p>Implement a Low Total cost of Ownership design and deploy a scalable and futureproof solution to minimize OpEx and operational overheads.</p>
                          <router-link to="/hotel-operators" class="default-btn mt-4">
                              Read More <span></span>
                          </router-link>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <Footer />
  </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import Footer from '../Layout/Footer'
import PageTitle from '../Common/PageTitle'

export default {
  components: {
      Navbar,
      Footer,
      PageTitle,
  }
}
</script>