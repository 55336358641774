<template>
    <div>
        <div class="machine-learning-slider">
            <carousel
                :autoplay="5000"
                :settings='settings' 
                :wrap-around="true"
                :mouseDrag="carouselItems.length > 1 ? true : false"
                :touchDrag="carouselItems.length > 1 ? true : false"
            >
                <slide
                    v-for="slide in carouselItems" 
                    :key="slide.id"
                >
                    <div :class="['machine-learning-banner', slide.class]">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <div class="container mt-80">
                                    <div class="row align-items-center">
                                        <div class="col-lg-7">
                                            <div class="banner-content">
                                                <h1 style="color: #212427;">{{slide.heading}}</h1>
                                                <p style="color: #212427;">{{slide.desc}}</p>
                                                <div class="banner-btn">
                                                    <router-link to="/contact" class="default-btn mr-4">
                                                        Get Started <span></span>
                                                    </router-link>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-5">
                                            <div class="ml-video">
                                                <div class="solution-video">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="shape-img2"><img src="../../assets/images/shape/shape-2.svg" alt="image"></div>
                        <div class="shape-img3"><img src="../../assets/images/shape/shape-3.svg" alt="image"></div>
                        <div class="shape-img4"><img src="../../assets/images/shape/shape-4.png" alt="image"></div>
                        <div class="shape-img5"><img src="../../assets/images/shape/shape-5.png" alt="image"></div>
                        <div class="shape-img6"><img src="../../assets/images/shape/shape-6.png" alt="image"></div>
                        <div class="shape-img7"><img src="../../assets/images/shape/shape-7.png" alt="image"></div>
                        <div class="shape-img8"><img src="../../assets/images/shape/shape-8.png" alt="image"></div>
                        <div class="shape-img9"><img src="../../assets/images/shape/shape-9.png" alt="image"></div>
                        <div class="shape-img10"><img src="../../assets/images/shape/shape-10.png" alt="image"></div>
                    </div>
                </slide>
            </carousel>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Navigation, Pagination } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'MainBannerSeven',
    components: {
        Carousel,
        Slide,
        Navigation,
        Pagination,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        carouselItems: [
            {
                id: 1,
                class: 'ml-bg1',
                heading: 'Engineering Services for Hospitality Building Automation',
                desc: 'We are a hospitality automation competence center providing engineering services to KNX manufacturers, project managers, developers, hotel operators and other hospitality stakeholders',
            },
        ],
        isPopup: false,
    }),
    methods: {
        isPopupMethod(isPopup){
            return this.isPopup = !isPopup
        },
    },
})
</script>