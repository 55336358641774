<template>
    <div class="terms-conditions ptb-100">
        <div class="container">
            <div class="single-privacy">
                <h3 class="mt-0">Privacy Policy</h3>
                <p>At OWL AUTOMATA, we value and respect your privacy. This Privacy Policy outlines how we collect, use, and protect your personal information when you access and use our website.</p>

                <h4>Information We Collect</h4>
                <p>When you visit our website, we may collect certain information, including:</p>
                <ul>
                    <li>Your name, email address, and contact information, which you provide to us voluntarily through our contact forms or when you communicate with us via email.</li>
                    <li>Usage data, such as your IP address, browser type, operating system, referring website, pages visited, and the date and time of your visit. We collect this information using cookies and similar technologies.</li>
                </ul>

                <h4>Use of Information</h4>
                <p>We use the collected information for the following purposes:</p>
                <ul>
                    <li>To communicate with you and respond to your inquiries.</li>
                    <li>To provide and improve our services, including personalizing your experience on our website.</li>
                    <li>To analyze and understand how users interact with our website and improve its functionality and performance.</li>
                    <li>To send you promotional emails or newsletters if you have subscribed to our mailing list. You can opt-out of receiving these communications at any time.</li>
                </ul>

                <h4>Protection of Information</h4>
                <p>We take appropriate measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, please note that no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.</p>

                <h4>Sharing of Information</h4>
                <p>We do not sell, trade, or otherwise transfer your personal information to third parties without your consent, except in the following circumstances:</p>
                <ul>
                    <li>If required by law or legal process, we may disclose your information to comply with any applicable regulations, court orders, or government requests.</li>
                    <li>If necessary to enforce our website policies or protect our rights, property, or safety, we may share information with trusted third parties.</li>
                    <li>If our business undergoes a merger, acquisition, or sale, your information may be transferred as part of that transaction, subject to the acquirer's privacy policy.</li>
                </ul>

                <h4>Third-Party Links</h4>
                <p>Our website may contain links to third-party websites. These websites have their own privacy policies, and we are not responsible for their practices. We encourage you to review the privacy policies of those third-party websites before providing any personal information.</p>

                <h4>Children's Privacy</h4>
                <p>Our website is not intended for children under the age of 13. We do not knowingly collect or solicit personal information from children. If we discover that we have collected personal information from a child without parental consent, we will promptly delete that information.</p>

                <h4>Updates to this Privacy Policy</h4>
                <p>We may update this Privacy Policy from time to time. The most current version will be posted on this page with the effective date.</p>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'PrivacyPolicyContent'
}
</script>