<template>
    <div class="terms-conditions ptb-100">
        <div class="container">
            <div class="single-privacy">
                <h3 class="mt-0">Welcome to OWL AUTOMATA's website.</h3>
                <p>By accessing and using this website, you agree to comply with and be bound by the following terms and conditions. Please read these terms carefully before using our website.</p>
                <h4>1. Acceptance of Terms</h4>
                <p>By accessing or using our website, you acknowledge that you have read, understood, and agree to be bound by these terms and conditions. If you do not agree with any of these terms, please do not use our website.</p>

                <h4>2. Intellectual Property</h4>
                <p>All content on this website, including but not limited to text, graphics, logos, images, videos, and software, is the property of OWL AUTOMATA or its licensors and is protected by intellectual property laws. You may not reproduce, distribute, modify, display, perform, or use any of the content without prior written consent from OWL AUTOMATA.</p>

                <h4>3. Use of Website</h4>
                <p>You may use our website for informational purposes and to engage with our services. However, you agree not to engage in any activity that may disrupt or interfere with the proper functioning of the website or infringe upon the rights of others.</p>

                <h4>4. Links to Third-Party Websites</h4>
                <p>Our website may contain links to third-party websites for your convenience and reference. OWL AUTOMATA does not endorse or have control over the content, products, or services provided on these third-party websites. Visiting these websites is at your own risk, and you should review their respective terms and conditions.</p>

                <h4>5. Limitation of Liability</h4>
                <p>While we strive to provide accurate and up-to-date information on our website, OWL AUTOMATA makes no warranties or representations about the accuracy, completeness, reliability, or suitability of the information, products, services, or graphics on the website. You acknowledge that the use of our website is at your own risk, and OWL AUTOMATA shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of your access to or use of the website.</p>

                <h4>6. Privacy Policy</h4>
                <p>Your privacy is important to us. Please refer to our Privacy Policy to understand how we collect, use, and protect your personal information.</p>

                <h4>7. Changes to Terms and Conditions</h4>
                <p>OWL AUTOMATA reserves the right to modify or update these terms and conditions at any time without prior notice. It is your responsibility to review these terms periodically to stay informed about any changes.</p>

                <h4>8. Governing Law and Jurisdiction</h4>
                <p>These terms and conditions shall be governed by and construed in accordance with the laws of the jurisdiction in which OWL AUTOMATA is located. Any legal disputes arising from or relating to these terms and conditions shall be subject to the exclusive jurisdiction of the courts in that jurisdiction.</p>

                <p>If you have any questions or concerns regarding these terms and conditions, please contact us at the provided contact information.</p>
                <strong>Last updated: 19/05/2023</strong>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'TermsConditionsContent'
}
</script>