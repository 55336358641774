import { createWebHistory, createRouter } from "vue-router";
// import { getAuth, onAuthStateChanged } from 'firebase/auth';
// import { getFirestore, doc, getDoc } from 'firebase/firestore';

// All Pages
import HomePageFive from '../components/Pages/HomePageFive'
import AboutPageTwo from '../components/Pages/AboutPageTwo'
import TermsConditionsPage from '../components/Pages/TermsConditionsPage'
import PrivacyPolicyPage from '../components/Pages/PrivacyPolicyPage'
import CookiePolicyPage from '../components/Pages/CookiePolicyPage'
import HaasPage from '../components/Pages/HaasPage'
import chameleonPage from '../components/Pages/chameleonPage'
import FireantPage from '../components/Pages/FireantPage'
import ServicesPageThree from '../components/Pages/ServicesPageThree'
import ContactPage from '../components/Pages/ContactPage'
import ConsultantsPage from '../components/Pages/ConsultantsPage'
import DevelopersPage from '../components/Pages/DevelopersPage'
import ManufacturersPage from '../components/Pages/ManufacturersPage'
import ProjectManagersPage from '../components/Pages/ProjectManagersPage'
import WholesalersPage from '../components/Pages/WholesalersPage'
import SystemIntegratorsPage from '../components/Pages/SystemIntegratorsPage'
import ContractorsPage from '../components/Pages/ContractorsPage'
import HotelOperatorsPage from '../components/Pages/HotelOperatorsPage'
import CustomersPage from '../components/Pages/CustomersPage'
// import AccountPage from '../components/Pages/Account'
// import RegisterPage from '../components/Pages/SignupPage'
// import LoginPage from '../components/Pages/LoginPage'
import NotFound from '../components/Pages/NotFound'
// import VerifyEmail from '../components/Pages/VerifyEmail'
// import PasswordReset from '../components/Pages/PasswordReset'
// import AdminPanel from '../components/Pages/AdminPanel'


const routes = [
    { path: '/', component: HomePageFive, meta: { title: 'OWL AUTOMATA - Owl Automata: Leading Engineering Solutions in Hospitality Building Automation', description: 'Explore Owl Automata for innovative engineering services in hospitality automation. Specializing in building automation, we offer customized solutions to enhance efficiency and comfort in the hospitality industry.' } },
    { path: '/about', component: AboutPageTwo, meta: { title: 'About Owl Automata – Leading Innovators in Hospitality Automation Engineering', description: 'Discover Owl Automata: your expert partner in engineering and automation solutions for the hospitality industry. Learn about our journey, values, and commitment to delivering state-of-the-art building automation services.' }},
    { path: '/terms-conditions', component: TermsConditionsPage, meta: { title: 'Owl Automata Terms & Conditions: Your Guide to Our Services', description: 'Understand your rights and responsibilities with Owl Automata\'s Terms & Conditions. Our guidelines ensure a clear understanding of our engineering and automation services in the hospitality industry.' } },
    { path: '/privacy-policy', component: PrivacyPolicyPage, meta: { title: 'Owl Automata Privacy Policy: Safeguarding Your Data', description: 'Read Owl Automata\'s Privacy Policy to understand how we protect and manage your personal data. Committed to privacy and transparency in our engineering and automation services for the hospitality industry.' } },
    { path: '/cookie-policy', component: CookiePolicyPage, meta: { title: 'Owl Automata Cookie Policy: Safeguarding Your Data', description: 'Read Owl Automata\'s Cookie Policy to understand how we protect and manage your cookies. Committed to privacy and transparency in our engineering and automation services for the hospitality industry.' } },
    { path: '/services', component: ServicesPageThree, meta: { title: 'Owl Automata Services: Expert Engineering for Hospitality Automation', description: 'Discover our range of specialized engineering services at Owl Automata, catering to the unique needs of the hospitality automation industry. From system design to integration, we provide innovative solutions.' }},
    { path: '/contact', component: ContactPage, meta: { title: 'Contact Owl Automata: Connect with Our Hospitality Automation Experts', description: 'Get in touch with Owl Automata for your engineering and automation needs in the hospitality industry. Contact us today to discuss solutions, services, and partnerships.' }},
    { path: '/consultants', component: ConsultantsPage, meta: { title: 'Expert Consultancy at Owl Automata: Pioneering Hospitality Automation Solutions', description: 'Meet our team of skilled consultants at Owl Automata, dedicated to providing top-tier engineering and automation advice for the hospitality industry. Collaborate with us for innovative, custom solutions.' }},
    { path: '/haas', component: HaasPage, meta: { title: 'Expert Consultancy at Owl Automata: Pioneering Hospitality Automation Solutions', description: 'Discover Chameleon, the first product in the HaaS series, designed for hotel automation professionals in Greece and Cyprus. Based on experience from over 30,000 hotel rooms, it offers a decentralized architecture and open communication protocol for reliability and maximum connectivity. Integrating Siemens LOGO! controllers with the KNX protocol, Chameleon provides a cost-effective, feature-rich room automation solution for hotels with 1 to 90 rooms.' }},
    { path: '/chameleon', component: chameleonPage, meta: { title: 'Chameleon HaaS: Advanced Hotel Automation Solution for Greece and Cyprus', description: 'Discover Chameleon, the first product in the HaaS series, designed for hotel automation professionals in Greece and Cyprus. Based on experience from over 30,000 hotel rooms, it offers a decentralized architecture and open communication protocol for reliability and maximum connectivity. Integrating Siemens LOGO! controllers with the KNX protocol, Chameleon provides a cost-effective, feature-rich room automation solution for hotels with 1 to 90 rooms.' }},
    { path: '/fire-ant', component: FireantPage, meta: { title: 'Fire Ant HaaS: Automated Solutions for Repetitive Tasks in Automation Projects', description: 'Fire Ant HaaS revolutionizes automation projects by using Robotic Process Automation to eliminate repetitive tasks like hotel visualization room creation. Reduce costs and labor while ensuring high-quality, error-free results. Focus on application design and let Fire Ant handle the rest. Get a quotation today!' }},
    { path: '/developers', component: DevelopersPage, meta: { title: 'Owl Automata Developers: Innovating Hospitality Automation Engineering', description: 'Explore the edge of hospitality automation with Owl Automata\'s developers. Dedicated to engineering excellence, our team creates advanced solutions for the evolving needs of the hospitality industry.' }},
    { path: '/manufacturers', component: ManufacturersPage, meta: { title: 'Partnering with Manufacturers - Owl Automata\'s Hospitality Automation Solutions', description: 'Owl Automata collaborates with leading manufacturers to revolutionize hospitality automation. Discover our bespoke engineering solutions designed to meet the unique needs of the manufacturing sector.' }},
    { path: '/project-managers', component: ProjectManagersPage, meta: { title: 'Expert Project Management at Owl Automata for Hospitality Automation', description: 'Discover Owl Automata\'s approach to project management in hospitality automation. Our team ensures efficient, innovative engineering solutions for every project phase in the hospitality industry.' }},
    { path: '/wholesalers', component: WholesalersPage, meta: { title: 'Owl Automata for Wholesalers: Top-Notch Hospitality Automation Engineering"', description: 'Owl Automata partners with wholesalers to offer cutting-edge automation solutions in the hospitality sector. Explore our engineering services tailored to meet the wholesale market demands.' }},
    { path: '/system-integrators', component: SystemIntegratorsPage, meta: { title: 'Owl Automata: Advanced System Integration for Hospitality Automation', description: 'Owl Automata specializes in system integration for the hospitality industry, providing sophisticated engineering and automation solutions. Our expertise bridges technology and functionality for optimum results.' }},
    { path: '/contractors', component: ContractorsPage, meta: { title: 'Owl Automata for Contractors: Engineering Excellence in Hospitality Automation', description: 'Owl Automata provides contractors with advanced engineering and automation solutions for the hospitality sector. Discover our services designed to enhance project efficiency and innovation.' }},
    { path: '/hotel-operators', component: HotelOperatorsPage, meta: { title: 'Owl Automata: Tailored Engineering Solutions for Hotel Operators', description: 'Empower your hospitality business with Owl Automata\'s specialized engineering and automation services. Our solutions are designed to enhance efficiency and innovation for hotel operators.' }},
    { path: '/customers', component: CustomersPage, meta: { title: 'Owl Automata’s Esteemed Customers: Partners in Hospitality Automation Success', description: 'Explore the diverse range of Owl Automata’s customers, including industry leaders in hospitality automation. Our commitment to engineering excellence drives successful partnerships and innovative solutions.' }},
    // { path: '/register', component: RegisterPage, meta: { title: 'Register', description: 'Register' }},
    // { path: '/login', component: LoginPage, meta: { title: 'Log in', description: 'Log in' }},
    // { path: '/verify-email', component: VerifyEmail, meta: { title: 'Verify Email', description: 'Verify your email address' }},
    // { path: '/password-reset', component: PasswordReset, meta: { title: 'Password Reset', description: 'Password Reset' }},
    // {
    //   path: '/admin',
    //   component: AdminPanel,
    //   beforeEnter: async (to, from, next) => {
    //     try {
    //       const isAdmin = await checkAdminStatus();
    //       if (isAdmin) {
    //         next(); // User is admin, allow access
    //       } else {
    //         next('/account'); // User is not admin, redirect to login or a 'not authorized' page
    //       }
    //     } catch (error) {
    //       console.error("Error in navigation guard: ", error);
    //       next('/'); // In case of error, redirect to a safe route
    //     }
    //   },
    //   meta: { title: 'Admin', description: 'Admin' }
    // },
    // {
    //   path: '/account',
    //   component: AccountPage,
    //   meta: { requiresAuth: true, title: 'My Account', description: 'My Account' }
    // },
    {
      path: "/:catchAll(.*)",
      name: "NotFound",
      component: NotFound,
  }
 ]

const router = createRouter({
    history: createWebHistory(),
    linkExactActiveClass: "active",
    routes,
    scrollBehavior() {
      return { top: 0 };
    },
});
const DEFAULT_TITLE = 'OWL AUTOMATA - Engineering Services for Hospitality Building Automation';
const DEFAULT_DESCRIPTION = 'OWL AUTOMATA is a global company specializing in engineering services for the hospitality building automation market. With a unique approach and a proven track record, we offer solutions that generate added value and differentiation for KNX manufacturers and other stakeholders. Explore our services and expertise in specification, solution calculation, schematics, sales argumentation, and more. Join us in driving horizontal integration and unlocking the full potential of hospitality applications.'

// const getCurrentUser = () => {
//   return new Promise((resolve, reject) => {
//     const removeListener = onAuthStateChanged(
//       getAuth(),
//       (user) => {
//         removeListener();
//         resolve(user);
//       },
//       reject
//     )
//   });
// }

// const checkAdminStatus = async () => {
//   const db = getFirestore();
//   const user = await getCurrentUser(); // Wait for the user state

//   if (user) {
//     const userRef = doc(db, "users", user.uid);
//     try {
//       const docSnap = await getDoc(userRef);
//       const isAdmin = docSnap.exists() && docSnap.data().role === 'admin';
//       return isAdmin;
//     } catch (error) {
//       console.error("Error checking admin status: ", error);
//       return false;
//     }
//   } else {
//     console.log("No user logged in");
//     return false;
//   }
// };


// router.beforeEach( async (to, from, next) => {
//   if (to.matched.some((record) => record.meta.requiresAuth)) {
//     const user = await getCurrentUser();

//     if (user && user.emailVerified) {
//       // User is authenticated and email is verified
//       next();
//     } else {
//       // User is not authenticated or email is not verified
//       alert('Access denied. Please verify your email.');
//       next('/login'); // Redirect to login page or any other page you prefer
//     }
//   } else {
//     // No authentication required for this route
//     next();
//   }
// });

router.afterEach((to) => {
  document.title = to.meta.title || DEFAULT_TITLE;
  let metaDescription = document.querySelector('meta[name="description"]');

  if (metaDescription) {
    metaDescription.setAttribute('content', to.meta.description || DEFAULT_DESCRIPTION);
  }
});

export default router;