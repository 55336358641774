<template>
    <div class="contact-area pt-5 mt-4">
        <div class="container">
            <div class="section-title">
                <h1 class="sub-title">Contact Us</h1>
                <h2>Drop us Message for any Query</h2>
                <p>Get in touch with our expert team</p>
            </div>

            <div class="row align-items-center">
                <div class="col-lg-4 col-md-4 order-2 order-md-1 mt-4 mt-md-0">
                    <div class="contact-image">
                        <img src="../../assets/images/owl-contact.jpg" class="rounded-10" alt="Contact Owl-Automata.com">
                    </div>
                </div>

                <div class="col-lg-8 col-md-8 order-1 order-md-2">
                    <div class="contact-form">
                        <form id="contactForm" name="contactowl" method="POST" data-netlify="true" data-netlify-honeypot="bot-field" @submit.prevent="handleSubmit()">
                            <input type="hidden" name="form-name" value="contactowl" />
                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <div class="form-group">
                                        <input type="text" name="name" v-model="form.name" id="name" class="form-control" required placeholder="Name">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-12">
                                    <div class="form-group">
                                        <input type="email" name="email" v-model="form.email" id="email" class="form-control" required placeholder="Email">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-12">
                                    <div class="form-group">
                                        <input type="text" name="subject" v-model="form.subject" id="subject" class="form-control" required placeholder="Subject">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-12">
                                    <div class="form-group">
                                        <input type="text" name="company" v-model="form.company" id="company" class="form-control" placeholder="Company">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <select name="role" v-model="form.role" id="role" class="form-control" placeholder="Role" required>
                                            <option value="" selected disabled>Please Select Your Role</option>
                                            <option value="Developer">Developer</option>
                                            <option value="Project Manager">Project Manager</option>
                                            <option value="Manufacturer">Manufacturer</option>
                                            <option value="Wholesaler">Wholesaler</option>
                                            <option value="System Integrator">System Integrator</option>
                                            <option value="Consultant">Consultant</option>
                                            <option value="Contractor">Contractor</option>
                                            <option value="Hotel Operator">Hotel Operator</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="message" class="form-control" v-model="form.message" id="message" cols="30" rows="5" required placeholder="Your Message"></textarea>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="default-btn">Send Message <span></span></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="row pt-5 mt-3 pb-5">
                <div class="col-lg-4 col-md-6 col-sm-6 mx-auto">
                    <div class="contact-info-box">
                        <div class="icon">
                            <i class="flaticon-email"></i>
                        </div>

                        <h3>Email Us</h3>
                        <p><a href="mailto:info@owl-automata.com">info@owl-automata.com</a></p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6 mx-auto">
                    <div class="contact-info-box">
                        <div class="icon">
                            <i class="flaticon-marker"></i>
                        </div>
                        <h3>Our Location</h3>
                        <p><a href="https://goo.gl/maps/jTC4JFhJLqtz884K8" target="_blank">Athens, Greece</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Swal from 'sweetalert2';
export default {
    name: 'ContactContent',
    data() {
        return {
            name: '',
            email: '',
            subject: '',
            company: '',
            role: '',  // Default this to an empty string or a placeholder value
            message: '',
        };
    },
    computed: {
        form() {
            return {
                name: this.name,
                email: this.email,
                subject: this.subject,
                company: this.company,
                role: this.role,
                message: this.message,
            };
        },
    },
    methods: {
        encode (data) {
            return Object.keys(data)
                .map(
                    key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
                )
                .join("&");
        },
        handleSubmit () {
            const axiosConfig = {
                header: { "Content-Type": "application/x-www-form-urlencoded" }
            };
            axios.post(
                "/",
                this.encode({
                    "form-name": "contactowl",
                    ...this.form
                }),
                axiosConfig
            )
            .then(() => {
                Swal.fire({
                    title: 'Success!',
                    text: 'Your Message Has Been Submitted Successfully',
                    icon: 'success',
                    confirmButtonText: 'Close'
                });
            })
            .catch(() => {
                Swal.fire({
                    title: 'Error!',
                    text: 'An Error Has Occured',
                    icon: 'error',
                    confirmButtonText: 'Close'
                });
                console.log('error');
            });
        }
    }
}
</script>
