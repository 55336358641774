<template>
  <div>
      <Navbar />
      <PageTitle pageTitle="Contractors" subTitle="Services targeted at contractors offer a manufacturer agnostic approach, while completing project objectives"/>
      <div class="container">
        <div class="overview-box mt-5">
            <div class="overview-image">
                <div class="image">
                    <img src="../../assets/images/machine-learning/owl-value.jpg" alt="Owl-Automata Value offering" class="rounded-10" />
                </div>
            </div>
            <div class="overview-content mb-0">
                <div class="content right-content">
                    <h2>Value offering</h2>
                    <div class="features-text">
                        <h4><i class="flaticon-tick"></i> Project Definition</h4>
                    </div>

                    <div class="features-text">
                        <h4><i class="flaticon-tick"></i> Adaptation of project specifications and guidelines to meet local specificities
</h4>
                    </div>

                    <div class="features-text">
                        <h4><i class="flaticon-tick"></i> Developing and deploying unbiased and manufacturer agnostic solutions
</h4>
                    </div>
                    <div class="features-text">
                        <h4><i class="flaticon-tick"></i> Provision of a holistic solution to the customer by driving horizontal integration of all areas of hospitality applications like GRMS, Access control, HVAC, Occupancy detection, Door lock systems, PMS, LV/MV, and audio/video integration

</h4>
                    </div>
                </div>
            </div>
        </div>
        <WeAlwaysTryToUnderstandContractors />
        <div class="mx-auto text-center mb-5">
            <p class="mt-4">*We remain at your disposal for any further information or clarification you might require.</p>
            <div class="contact-btn mt-5">
                <router-link to="/contact" class="default-btn">
                    Contact Us <span></span>
                </router-link>
            </div>
        </div>
      </div>
      <Footer />
  </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import Footer from '../Layout/Footer'
import WeAlwaysTryToUnderstandContractors from '../Common/WeAlwaysTryToUnderstandContractors'

export default {
  components: { 
      Navbar,
      PageTitle,
      Footer,
      WeAlwaysTryToUnderstandContractors,
  }
}
</script>