<template>
    <div class="pb-100 pt-5">
        <div class="container">
            <div class="row mb-5">
                <div class="col-12 text-center">
                    <p>Η OWL AUTOMATA εισάγει στην αγορά την κατηγορία προϊόντων HaaS για ξενοδοχειακές εφαρμογές, προσφέροντας μία σειρά τεχνικών υπηρεσιών στους αυτοματιστές που δραστηριοποιούνται στην ξενοδοχειακή αγορά</p>
                </div>
            </div>
            <div class="row mb-5">
                <div class="col-12 col-md-6 text-center text-md-start mb-5">
                    <h3>Τι είναι το HaaS</h3>
                    <p>Το Hotel as a Service ή HaaS είναι ένα μοντέλο λειτουργίας στα πρότυπα του Software as a Service, όπου το σύνολο των ενεργειών που απαιτούνται για την ολοκλήρωση ενός έργου ξενοδοχείου, διαχωρίζεται σε αυτές οι οποίες εκτελούνται τοπικά από τον αυτοματιστή και αυτές που εκτελούνται μέσω αυτόματων διαδικασιών από την OWL Automata.</p>
                    <p>Ο σκοπός του είναι να εξυπηρετήσει τους αυτοματιστές που δραστηριοποιούνται ή επιθυμούν να δραστηριοποιηθούν στην εξαιρετικά ανταγωνιστική αγορά του ξενοδοχειακού αυτοματισμού.</p>
                </div>
                <div class="col-12 col-md-6">
                    <img src="../../assets/images/haas.png" alt="">
                </div>
                <div class="col-12 text-center mt-5">
                    <h3 class="mb-4">Πλεονεκτήματα</h3>
                    <ul style="list-style-position: inside; list-style-type: none; padding-left: 0;">
                        <li class="mb-3"><i class="flaticon-tick"></i> <span style="margin-left: 8px;">Ελαχιστοποίηση χρόνου παράδοσης του έργου</span></li>
                        <li class="mb-3"><i class="flaticon-tick"></i> <span style="margin-left: 8px;">Πρόσβαση σε τεχνογνωσία ξενοδοχειακής εφαρμογής</span></li>
                        <li class="mb-3"><i class="flaticon-tick"></i> <span style="margin-left: 8px;">Μείωση λειτουργικών εξόδων σε επαναλαμβανόμενες εργασίες</span></li>
                        <li class="mb-3"><i class="flaticon-tick"></i> <span style="margin-left: 8px;">Αποκτήστε εξειδίκευση στον ξενοδοχειακό αυτοματισμό</span></li>
                        <li class="mb-3"><i class="flaticon-tick"></i> <span style="margin-left: 8px;">Ευκολία στην χρήση και τις δοκιμές</span></li>
                    </ul>
                </div>
            </div>
            <div class="row d-flex justify-content-center mt-4">
                <div class="col-12 text-center mb-4">
                    <h3>Λύσεις</h3>
                    <p>Εξερευνήστε την λύση που σας εξυπηρετεί στο ξενοδοχειακό έργο σας</p>
                </div>
                <div class="col-6 col-sm-3 col-lg-2 text-center">
                    <router-link to="/chameleon">
                        <div class="card">
                            <img src="../../assets/images/Chameleon-3.png" alt="" class="img-fluid" style="width: 100%;">
                        </div>
                        <h4 class="text-center mt-2">Chame<span style="color: #ef3f23;">l</span>e<span style="color: #ef3f23;">o</span>n</h4>
                        <p>Plug and play πλήρη λύση προηγμένου ξενοδοχειακού αυτοματισμού έως 90 δωμάτια</p>
                    </router-link>
                </div>
                <div class="col-6 col-sm-3 col-lg-2 text-center">
                    <router-link to="/fire-ant">
                        <div class="card">
                            <img src="../../assets/images/fireant-2.png" alt="" class="img-fluid" style="width: 100%;">
                        </div>
                        <h4 class="text-center mt-2">Fi<span style="color: #ef3f23;">r</span>e <span style="color: #ef3f23;">A</span>nt</h4>
                        <p>Αυτοματοποιούμε τις εργασίες προγραμματισμού του έργου σας</p>
                    </router-link>
                </div>
                <div class="col-6 col-sm-3 col-lg-2 mt-5 mt-sm-0 text-center">
                    <router-link to="//owl-companion-haas.glide.page" target="_blank" rel="noopener noreferer">
                        <div class="card">
                            <img src="../../assets/images/qr-1.png" alt="" class="img-fluid" style="width: 100%;">
                        </div>
                        <h4 class="text-center mt-2">Microsite προϊόντων <span style="color: #000;">H</span><span style="color: #d8d6d6;">aa</span><span style="color: #ef3f23;">S</span></h4>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'HaasContent'
}
</script>
