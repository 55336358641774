<template>
    <div class="">
        <div class="pt-100 gray-bg">
            <div class="container">
                <div class="section-title">
                    <h2>Serving a diverse group of customers</h2>
                    <p>At OWL Automata, we cater to the needs of various stakeholders in the building automation industry.</p>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-sm-6">
                        <div class="service-card-one bg-white center">
                            <div class="icon">
                                <i class="bx bx-laptop"></i>
                                <i class='bx bxs-badge-check check-icon'></i>
                            </div>
                            <h3>
                                Developers
                            </h3>
                            <p>Acquire the means for deployment of an optimal manufacturer agnostic solution, easily adapting in different projects and local specificities.</p>
                            <router-link to="/developers" class="default-btn mt-4">
                                <span class="visually-hidden">Learn more about Developers</span>
                                Read More <span></span>
                            </router-link>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <div class="service-card-one bg-white center">
                            <div class="icon">
                                <i class="bx bxs-user-badge"></i>
                                <i class='bx bxs-badge-check check-icon'></i>
                            </div>
                            <h3>
                                Project managers
                            </h3>
                            <p>Make a specialized technical partner for every stage of the project; Design, Engineering, Installation and Completion phases.</p>
                            <router-link to="/project-managers" class="default-btn mt-4">
                                <span class="visually-hidden">Learn more about Project managers</span>
                                Read More <span></span>
                            </router-link>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <div class="service-card-one bg-white center">
                            <div class="icon">
                                <i class="bx bx-cog"></i>
                                <i class='bx bxs-badge-check check-icon'></i>
                            </div>
                            <h3>
                                Manufacturers
                            </h3>
                            <p>Make a specialized technical partner for every stage of the project; Design, Engineering, Installation and Completion phases.</p>
                            <router-link to="/manufacturers" class="default-btn mt-4">
                                <span class="visually-hidden">Learn more about Manufacturers</span>
                                Read More <span></span>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="text-center">
                    <router-link to="/customers" class="default-btn mt-4">
                        All Our Customers
                    </router-link>
                </div>
            </div>
        </div>
        <div class="pt-100 pb-70 gray-bg">
            <div class="container">
                <div class="section-title">
                    <h2>Our Services</h2>
                    <p>At OWL Automata, we provide a comprehensive package of engineering services tailored to the specific needs of the hospitality sector. Our offering includes:</p>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-sm-6">
                        <div class="service-card-one bg-white center">
                            <div class="icon">
                                <i class="bx bx-food-menu"></i>
                                <i class='bx bxs-badge-check check-icon'></i>
                            </div>
                            <h3>
                                Project specification
                            </h3>
                            <p>Project definition, ensuring a clear roadmap for implementation.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <div class="service-card-one bg-white center">
                            <div class="icon">
                                <i class="bx bx-check-shield"></i>
                                <i class='bx bxs-badge-check check-icon'></i>
                            </div>
                            <h3>
                                Solution Calculation
                            </h3>
                            <p>Determining the necessary components and materials (Bill of Materials) for seamless execution.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <div class="service-card-one bg-white center">
                            <div class="icon">
                                <i class="bx bxs-calendar-edit"></i>
                                <i class='bx bxs-badge-check check-icon'></i>
                            </div>
                            <h3>
                                Functional Description
                            </h3>
                            <p>Developing comprehensive functional descriptions that outline the system control strategy.</p>
                        </div>
                    </div>
                </div>
                <div class="text-center">
                    <router-link to="/services" class="default-btn mt-4">
                        All Our Services <span></span>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'OurServices'
    }
</script>