<template>
  <div>
    <Navbar />
    <PageTitle pageTitle="System Integrators" subTitle="Make your way into the hospitality building automation market." />
    <div class="container">
      <div class="overview-box mb-3 mb-md-5 my-5">
        <div class="overview-image">
            <div class="image">
              <img src="../../assets/images/machine-learning/owl-value.jpg" alt="Owl-Automata Value offering" class="rounded-10" />
            </div>
        </div>
        <div class="overview-content mb-0">
            <div class="content right-content">
                <h2>Value offering</h2>
                <div class="features-text">
                    <h4><i class="flaticon-tick"></i> Be able to shift hostile specifications, by understanding project needs and adjusting your proposal accordingly</h4>
                </div>

                <div class="features-text">
                    <h4><i class="flaticon-tick"></i> Gain access to accumulated field knowledge of 10 years in hospitality projects.</h4>
                </div>

                <div class="features-text">
                    <h4><i class="flaticon-tick"></i> Provide a holistic solution to the customer by driving horizontal integration of all areas of hospitality applications</h4>
                    <ul class="features-list">
                      <li><span><i class="fas fa-check"></i> GRMS</span></li>
                      <li><span><i class="fas fa-check"></i> Access control</span></li>
                      <li><span><i class="fas fa-check"></i> HVAC</span></li>
                      <li><span><i class="fas fa-check"></i> Occupancy detection</span></li>
                      <li><span><i class="fas fa-check"></i> Door lock systems</span></li>
                      <li><span><i class="fas fa-check"></i> PMS</span></li>
                    </ul>
                </div>
            </div>
        </div>
      </div>
      <WeAlwaysTryToUnderstandSystemsIntegrators />
      <div class="mx-auto text-center mb-5">
          <p class="mt-4">*We remain at your disposal for any further information or clarification you might require.</p>
          <div class="contact-btn mt-5">
                <router-link to="/contact" class="default-btn">
                    Contact Us <span></span>
                </router-link>
            </div>
        </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import WeAlwaysTryToUnderstandSystemsIntegrators from '../Common/WeAlwaysTryToUnderstandSystemsIntegrators'
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import Footer from '../Layout/Footer'

export default {
  components: { 
      Navbar,
      PageTitle,
      Footer,
      WeAlwaysTryToUnderstandSystemsIntegrators,
  }
}
</script>