<template>
    <div>
        <div :class="['navbar-area is-sticky', {'is-sticky': isSticky}]">
            <div class="bionix-nav">
                <div class="container">
                    <nav class="navbar navbar-expand-lg navbar-light">
                        <router-link class="navbar-brand" to="/">
                            <img src="../../assets/images/logo.svg" alt="logo" width="160" height="43">
                        </router-link>

                        <div
                            class="navbar-toggler"
                            role="button"
                            aria-label="Toggle navigation"
                            @click="active = !active" :aria-pressed="active ? 'true' : 'false'"
                            v-bind:class="{ 'active': button_active_state }"
                            v-on:click="button_active_state = !button_active_state"
                        >
                            <span class="icon-bar top-bar"></span>
                            <span class="icon-bar middle-bar"></span>
                            <span class="icon-bar bottom-bar"></span>
                        </div>

                        <div class="collapse navbar-collapse" :class="{ show: active }">
                            <ul class="navbar-nav">
                                <li class="nav-item">
                                    <router-link to="/" class="nav-link" style="font-weight: 600;">Home</router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link to="/about" class="nav-link" style="font-weight: 600;">About Us</router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link to="/customers" class="nav-link" style="font-weight: 600;">Our Customers</router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link to="/services" class="nav-link" style="font-weight: 600;">Services</router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link to="/haas" class="nav-link" style="font-weight: 600;">HaaS</router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link to="/contact" class="nav-link" style="font-weight: 600;">Contact</router-link>
                                </li>
                                <!-- <li class="nav-item" v-if="isLoggedIn">
                                    <router-link to="/account" class="nav-link" style="font-weight: 600;">My Account</router-link>
                                </li>
                                <li class="nav-item" v-else>
                                    <router-link to="/login" class="nav-link" style="font-weight: 600;">Login</router-link>
                                </li> -->
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, watch, computed } from 'vue';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

export default {
    name: 'Navbar',
    setup() {
        const user = ref(null);
        const auth = getAuth();

        onMounted(() => {
            // Immediately check the current user's status
            user.value = auth.currentUser;

            // Listen for changes in authentication state
            onAuthStateChanged(auth, (firebaseUser) => {
                user.value = firebaseUser;
            });
        });

        const isLoggedIn = computed(() => {
            return user.value !== null && user.value.emailVerified;
        });

        // Watch for changes in the user object and update isLoggedIn accordingly
        watch(user, (newValue) => {
            isLoggedIn.value = newValue !== null && newValue.emailVerified;
        });

        return {
            isLoggedIn,
        }
    },
    data(){
        return {
            isSticky: false,
            isSearch: false,
            isMenu: false,
            active: false,
            button_active_state: false,
        }
    },
    mounted(){
        const that = this
        window.addEventListener('scroll', () => {
            let scrollPos = window.scrollY
            if(scrollPos >= 100){
                that.isSticky = true
            } else {
                that.isSticky = false
            }
        })
    },
    computed: {
        shoppingCart(){
            return this.$store.state.cart;
        }
    },
    methods: {
        isSearchMethod(isSearch){
            return this.isSearch = !isSearch
        },
    }
}
</script>