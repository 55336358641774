<template>
    <div class="footer-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-6 text-center text-md-start mb-5">
                    <router-link class="mx-auto" to="/">
                        <img src="../../assets/images/logo-white.svg" alt="logo" width="180" height="180" class="mx-auto">
                    </router-link>
                </div>

                <div class="col-lg-5 col-md-6 col-sm-6">
                    <div class="single-footer-widget pl-5">
                        <h3>Quick Links</h3>

                        <ul class="footer-quick-links">
                            <li><router-link to="/">Home</router-link></li>
                            <li><router-link to="/about">About Us</router-link></li>
                            <li><router-link to="/contact">Contact</router-link></li>
                            <li><router-link to="/services">Services</router-link></li>
                            <li><router-link to="/customers">Our Customers</router-link></li>
                            <li><router-link to="/haas">HaaS (Hotel as a Service)</router-link></li>
                            <li><router-link to="/chameleon">Chameleon</router-link></li>
                            <li><router-link to="/fire-ant">Fire Ant</router-link></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-footer-widget mb-0">
                        <h3>Contact Info</h3>

                        <ul class="footer-contact-info">
                            <li>
                                <i class="flaticon-email"></i>
                                <span>Do You Have a Question?</span>
                                <a href="mailto:info@owl-automata.com">info@owl-automata.com</a>
                            </li>
                        </ul>

                        <ul class="social-links inline-list">
                            <li><a href="https://www.linkedin.com/company/owl-automata/" target="_blank"><i class='bx bxl-linkedin text-white'></i></a></li>
                            <li><a href="https://www.youtube.com/@OWLAUTOMATA" target="_blank"><i class='bx bxl-youtube text-white'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="copyright-area">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-sm-6 col-md-6">
                        <p>&copy; {{currentYear}} OWL-Automata.com. All rights reserved <a href="https://owl-automata.com/" target="_blank">OWL Automata</a></p>
                    </div>

                    <div class="col-lg-6 col-sm-6 col-md-6">
                        <ul>
                            <li><router-link to="/terms-conditions">Terms & Conditions</router-link></li>
                            <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
                            <li><router-link to="/cookie-policy">Cookie Policy</router-link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Footer',
    data() {
        return {
            currentYear: new Date().getFullYear(),
        };
    }
}
</script>

<style lang="scss">
.social-links {
    margin-top: 20px;
    padding-left: 0;
    li {
        padding: 0 15px;
        display: inline;

        &:first-child {
            padding-left: 0;
        }
    }
    a {
        font-size: 29px;
    }
}
</style>