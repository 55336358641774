<template>
    <div class="">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="overview-box pt-5">
                        <div class="overview-image">
                            <div class="image">
                                <img src="../../assets/images/machine-learning/owl-value.jpg" class="rounded-10" alt="Owl-Automata Value">
                            </div>
                        </div>
                        <div class="overview-content">
                            <div class="content right-content">
                                <h2>Value offering</h2>
                                <div class="features-text">
                                    <h4><i class="flaticon-tick"></i> Focus on solutions and features that carry value for the stakeholders, allowing to be competitive while maintaining price levels and higher margins
                </h4>
                                </div>
            
                                <div class="features-text">
                                    <h4><i class="flaticon-tick"></i> Be able to shift hostile specifications, by understanding project needs and adjusting your proposal accordingly
                </h4>
                                </div>
            
                                <div class="features-text">
                                    <h4><i class="flaticon-tick"></i> Increase annual turnover by entering the rapidly growing hospitality market with a solution that has been proven to work
                </h4>
                                </div>
                                <div class="features-text">
                                    <h4><i class="flaticon-tick"></i> Gain access to accumulated field knowledge of 10 years in hospitality projects.
                </h4>
                                </div>
                                <div class="features-text">
                                    <h4><i class="flaticon-tick"></i> Provide a holistic solution to the customer by driving horizontal integration of all areas of hospitality applications
                </h4>
                                <ul class="features-list">
                                <li><span><i class="fas fa-check"></i> GRMS</span></li>
                                <li><span><i class="fas fa-check"></i> Access control</span></li>
                                <li><span><i class="fas fa-check"></i> HVAC</span></li>
                                <li><span><i class="fas fa-check"></i> Occupancy detection</span></li>
                                <li><span><i class="fas fa-check"></i> Door lock systems</span></li>
                                <li><span><i class="fas fa-check"></i> PMS</span></li>
                                <li><span><i class="fas fa-check"></i> LV/MV</span></li>
                                <li><span><i class="fas fa-check"></i> Audio/video integration</span></li>
                                </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row ptb-100">
                <div class="col-lg-4 col-sm-6">
                    <div class="service-card-one bg-white center">
                        <div class="icon">
                            <i class="bx bx-food-menu"></i>
                            <i class='bx bxs-badge-check check-icon'></i>
                        </div>
                        <h3>
                            Project specification
                        </h3>
                        <p>Project definition, ensuring a clear roadmap for implementation.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="service-card-one bg-white center">
                        <div class="icon">
                            <i class="bx bx-check-shield"></i>
                            <i class='bx bxs-badge-check check-icon'></i>
                        </div>
                        <h3>
                            Solution Calculation
                        </h3>
                        <p>Determining the necessary components and materials (Bill of Materials) for seamless execution.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="service-card-one bg-white center">
                        <div class="icon">
                            <i class="bx bxs-calendar-edit"></i>
                            <i class='bx bxs-badge-check check-icon'></i>
                        </div>
                        <h3>
                            Functional Description
                        </h3>
                        <p>Developing comprehensive functional descriptions that outline the system control strategy.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="service-card-one bg-white center">
                        <div class="icon">
                            <i class='bx bx-images'></i>
                            <i class='bx bxs-badge-check check-icon'></i>
                        </div>
                        <h3>
                            Drawing and Schematics
                        </h3>
                        <p>Connection drawings for every typical room.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="service-card-one bg-white center">
                        <div class="icon">
                            <i class='bx bxs-badge-dollar'></i>
                            <i class='bx bxs-badge-check check-icon'></i>
                        </div>
                        <h3>
                            Upselling
                        </h3>
                        <p>Identifying and exploiting opportunities for upselling throughout the building's life cycle.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="service-card-one bg-white center">
                        <div class="icon">
                            <i class='bx bx-code-block'></i>
                            <i class='bx bxs-badge-check check-icon'></i>
                        </div>
                        <h3>
                            Programming support
                        </h3>
                        <p>Programming support and third-party systems integration.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 mx-auto">
                    <div class="service-card-one bg-white center">
                        <div class="icon">
                            <i class='bx bx-dollar'></i>
                            <i class='bx bxs-badge-check check-icon'></i>
                        </div>
                        <h3>
                            Sales argumentation
                        </h3>
                        <p>Drafting of sales argumentation and presentations of the solution.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 mx-auto">
                    <div class="service-card-one bg-white center">
                        <div class="icon">
                            <i class='bx bx-calculator'></i>
                            <i class='bx bxs-badge-check check-icon'></i>
                        </div>
                        <h3>
                            Reliability calculation
                        </h3>
                        <p>Calculating your system's reliability quotient.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 mx-auto">
                    <div class="service-card-one bg-white center">
                        <div class="icon">
                            <i class='bx bx-spreadsheet'></i>
                            <i class='bx bxs-badge-check check-icon'></i>
                        </div>
                        <h3>
                            Presentations of solution
                        </h3>
                        <p>Visualizing the future of your project.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 mx-auto">
                    <div class="service-card-one bg-white center">
                        <div class="icon">
                            <i class='bx bx-line-chart'></i>
                            <i class='bx bxs-badge-check check-icon'></i>
                        </div>
                        <h3>
                            Upselling 3 stages of solution
                        </h3>
                        <p>Elevating your project at every stage.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 mx-auto">
                    <div class="service-card-one bg-white center">
                        <div class="icon">
                            <i class='bx bxs-user-voice'></i>
                            <i class='bx bxs-badge-check check-icon'></i>
                        </div>
                        <h3>
                            Support for meetings
                        </h3>
                        <p>Meetings made easy with our support.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 mx-auto">
                    <div class="service-card-one bg-white center">
                        <div class="icon">
                            <i class='bx bx-support'></i>
                            <i class='bx bxs-badge-check check-icon'></i>
                        </div>
                        <h3>
                            Post sales support
                        </h3>
                        <p>Ongoing support, from start to finish.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="mx-auto text-center mb-5">
                    <p>*We remain at your disposal for any further information or clarification you might require.</p>
                    <div class="contact-btn mt-5">
                        <router-link to="/contact" class="default-btn">
                            Contact Us <span></span>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ServicesCard',
        components: {
        }
    }
</script>