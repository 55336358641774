<template>
    <div>
        <div class="about-area-two pt-100 mt-5 mb-lg-5">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="about-image">
                            <img src="../../assets/images/about-owl-automata.jpg" alt="Owl-Automata Mission statement" class="rounded-10">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="about-content">
                            <div class="section-title text-left">
                                <span class="sub-title">About Us</span>
                                <h3>Mission statement</h3> 
                                <p>OWL AUTOMATA is a hospitality competence center that provides engineering services through all the stages of a project, enabling its partners to differentiate from the competition by specifying functionality and implementing a set of crucial engineering guidelines, and not describing products</p>
                            </div>
                            <div class="about-text">
                                <h4>Who we are</h4>
                                <p>Former ABB employees who were responsible for KNX building automation sales and management in the Greek and Cypriot market, with 20 years of combined experience in KNX and 15 in hospitality applications, with a total of 30.000 hotel rooms delivered in a span of ten years.
                                    During our tenure, we supported and expanded the markets of Greece and Cyprus, provided consulting services to local ABB sales units around the world
                                </p>
                            </div>
                            <div class="about-text">
                                <h4>Our Approach</h4>
                                <p>Unlike traditional approaches, we take a unique path by emphasizing functionality and implementing crucial engineering guidelines rather than simply describing products. Our proven track record of delivering over 30,000 rooms in the past 10 years across various countries, including Greece, Cyprus, Albania, Romania, and Iceland, attests to the merit and validity of our approach.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WeAredDynamicTeam',
    data (){
        return {
            isPopup: false
        }
    },
    methods: {
        isPopupMethod(isPopup){
            return this.isPopup = !isPopup
        },
    },
}
</script>